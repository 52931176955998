.search{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
}
.input{
    padding: 1rem;
}