@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap');

html,body {
    margin:0;
    padding:0;
    overflow-x:hidden;
}

body{
    background: rgba(105, 105, 105, 0.288);
}

*{
    font-family: 'Josefin Sans', sans-serif;
}

.container{
    max-width: 850px;
    margin: 0 auto;
}

@media screen and (max-width: 730px){

}